(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/navigation-indicator/views/next_partial.js') >= 0) return;  svs.modules.push('/components/ui/navigation-indicator/views/next_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.navigation_indicator=_svs.ui.navigation_indicator||{};
_svs.ui.navigation_indicator.partials=_svs.ui.navigation_indicator.partials||{};
svs.ui.navigation_indicator.partials.next = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"===","inverted",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":14}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"color":"icon-inverted","size":"300","icon":"menu-right"},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":68}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"size":"300","icon":"menu-right"},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":46}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"class":"navigation-indicator-button js-navigation-indicator-next navigation-indicator-next"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['ui-navigation-indicator-next'] = svs.ui.navigation_indicator.partials.next;
})(svs, Handlebars);


 })(window);