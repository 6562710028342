(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/navigation-indicator/assets/javascripts/navigation-indicator.js') >= 0) return;  svs.modules.push('/components/ui/navigation-indicator/assets/javascripts/navigation-indicator.js');

'use strict';

svs.ui = svs.ui || {};
svs.ui.NavigationIndicator = class NavigationIndicator {
  constructor(options) {
    this.currentItem = options.activeIndex || 0;
    this.options = options;
    this.el = this.options.el;
    this.prevButton = this.el.querySelector('.js-navigation-indicator-prev');
    this.nextButton = this.el.querySelector('.js-navigation-indicator-next');
    this.items = this.el.querySelectorAll('.js-navigation-indicator-dot');
    this.initMarkup();
    this.initEvents();
    this.renderCurrentItem();
  }
  initMarkup() {
    this.items.forEach((item, index) => {
      item.dataset.index = index;
    });
  }
  initEvents() {
    if (this.prevButton) {
      this.prevButton.addEventListener('click', () => {
        if (this.currentItem > 0) {
          this.setCurrentItem(this.currentItem - 1);
        }
      });
    }
    if (this.nextButton) {
      this.nextButton.addEventListener('click', () => {
        if (this.currentItem < this.items.length - 1) {
          this.setCurrentItem(this.currentItem + 1);
        }
      });
    }
    this.el.addEventListener('click', e => {
      if (e.target.classList.contains('js-navigation-indicator-dot')) {
        if (e.target.dataset && e.target.dataset.index !== undefined) {
          this.setCurrentItem(parseInt(e.target.dataset.index, 10));
        }
      }
    });
  }
  setCurrentItem(currentItem) {
    const shouldTriggerChange = currentItem !== this.currentItem;
    this.currentItem = currentItem;
    this.renderCurrentItem();
    if (this.options.onChange && shouldTriggerChange) {
      this.options.onChange(this.currentItem);
    }
  }
  renderCurrentItem() {
    this.items.forEach(item => {
      item.classList.remove('navigation-indicator-dot-active');
    });
    this.items[this.currentItem].classList.add('navigation-indicator-dot-active');
    if (this.prevButton) {
      this.prevButton.disabled = this.currentItem === 0;
    }
    if (this.nextButton) {
      this.nextButton.disabled = this.currentItem === this.items.length - 1;
    }
  }
};

 })(window);